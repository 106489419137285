import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Tab, Tabs, Popover  ,Tooltip,
  Position,
  Button,
  Icon,
  Tag,} from "@blueprintjs/core";

import AddAdditionalDataForm from "./AddAdditionalDataForm";
import { Helmet } from "react-helmet";
import SurveyDeploymentDialog from "../../survey-deployment/dialogs/SurveyDeploymentDialog";
import CreateCustomDataSetDialog from "./create-custom-data-set-dialog/CreateCustomDataSetDialog";
import SurveyDataTab from "./SurveyDataTab";
import DistrictComparisonTab from "./DistrictComparisonTab";
import SurveyUpsertDialog from "../../surveys/dialogs/SurveyUpsertDialog";
import AdministrationGuidelinesTab from "./administration-guidelines-tab/AdministrationGuidelinesTab";
import WorkspaceHeader from "../workspace-header/WorkspaceHeader";
import {
  ActivityTagEnum,
  UserNotesType,
  DistrictComparisonRouteParams,
  DomainQuestionsComparison,
  DomainComparison,
  SurveyDemographic,
  SurveyReportTabEnum,
} from "../../../types";
import CreateDatasetFromFileDialog from "./create-dataset-from-file-dialog/CreateDatasetFromFileDialog";
import ActivityResourcesTab from "../members/tabs/resources-tab/ActivityResourcesTab";
import { generatePath, useHistory, useLocation, useParams } from "react-router";
import { AuthRoutes } from "../../../App";
import useUserRole from "../../../helpers/hooks/useUserRole";
import { useSelector,useDispatch } from "react-redux";
import { isDistrictPlan } from "../../../store/plans/selectors";
import {
  getBooleanFromLocalStorage,
  LocalStorageKeys,
} from "../../../constants";
import CoordinateSurveyAdministrationDialog from "../members/tabs/team-home-tab/CoordinateSurveyAdministrationDialog";
import ReturnButton from "../../surveys/cards/ReturnButton";
import { getUrlParam } from "../../../helpers/url-utils";
import * as XLSX from "xlsx";
import { domain } from "process";
import { getSurveyDemographicForComparison } from "../../surveys/tab/useComparison";
import _ from "lodash";
import { isDomainQuestionsComparison } from "../../surveys/table/utils";
import Axios from 'axios';
import { API_ROOT } from "../../../api/config";
import { getComparisonDomainItemsData } from "../../../store/survey-reports/actions";

type OwnProps = {};

type Props = OwnProps;

export const REPORT_TYPE_PARAM = "report_type";

const CollectDataPage: FunctionComponent<Props> = (props) => {
  const intl = useIntl();

  const history = useHistory();

  const location = useLocation();
  const dispatch = useDispatch();

  const routeParams = useParams<DistrictComparisonRouteParams>();
  const selectedDomain = useSelector((s) => s.domains.selectedDomain);
  const { tabId } = useParams<{ tabId?: string }>();
  const { isTeamMember, user } = useUserRole();

  const surveyComparison:any = useSelector(
    (s) => s.surveyReports.districtComparison
  );

  const [isFirstTimeDialogWatched, setWatched] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setWatched(
        getBooleanFromLocalStorage(
          `${LocalStorageKeys.DataCollectionModalWatched}_${user.id}`
        )
      );
    }
  }, [user]);

  const isStrategicPlanEnabled = useSelector(
    (s) => s.plans.activePlan?.district?.enabled_strategic_plan
  );
  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const selectedSurveyDeployment = useSelector(
    (s) => s.surveysDeployment.selectedDistrictComarprisonSurvey
  );
  const activePlan = useSelector((s) => s.plans.activePlan);

  const comparisonItemsData=useSelector((s) => s.surveyReports.ComparisonDomainItemsData);
  const comparisonItemsDataLoading=useSelector((s) => s.surveyReports.loading.getComparisonDomainItemsData);


  const getSchoolDetail = (i: number) => {
    const sd = selectedSurveyDeployment?.[i];
    if (sd) {
      return (
              {
                deployment: sd?.deployment,
                administration: sd?.administration,
                month: sd?.month,
                year: sd?.year,
              }
      );
    }
    //  else {
    //   return (
    //     <Tooltip
    //       content={
    //         <div className="p-2">
    //           {intl.formatMessage({
    //             id: "app.district-comparison-table.total-mean-score.popover",
    //           })}
    //         </div>
    //       }
    //       position={Position.TOP}
    //       className="flex justify-center"
    //       popoverClassName="max-w-140"
    //       isOpen={totalMeanTooltipState}
    //     >
    //       <p className="text-center gap-2 text-lg font-semibold">
    //         {intl.formatMessage({
    //           id: "app.district-comparison-table.total-mean-score.title",
    //         })}
    //       </p>
    //     </Tooltip>
    //   );
    // }
  };

 
  // console.log("getSchoolDetail=>",getSchoolDetail(0).deployment)

  const selectedTabId: UserNotesType = useMemo(() => {
    return tabId &&
      [
        UserNotesType.DC_REPORTING_ADMINISTRATION_GUIDELINES,
        UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS,
        UserNotesType.DC_REPORTING_DISTRICT_COMPARISON,
        UserNotesType.DC_REPORTING_SUPPLEMENTAL_DATA,
        UserNotesType.DC_REPORTING_RESOURCES,
      ].includes(tabId as UserNotesType)
      ? (tabId as UserNotesType)
      : isDistrictActivePlan
      ? UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS
      : UserNotesType.DC_REPORTING_ADMINISTRATION_GUIDELINES;
  }, [tabId, isDistrictActivePlan]);

  const title = useMemo(() => {
    return intl.formatMessage({
      id: "app.titles.data-collection-and-reports",
    });
  }, []);
//  console.log("hello",surveyComparison[0].map((s)=>s.domain.name))

  const handleCoordinateSurveyAdministrationClose = () => {
    if (user) {
      localStorage.setItem(
        `${LocalStorageKeys.DataCollectionModalWatched}_${user.id}`,
        JSON.stringify(true)
      );
      setWatched(true);
    }
  };

  const buttonText = useMemo(() => {
    return intl.formatMessage(
      {
        id: "app.survey-report.selected-domain.return-button.text",
      },
      {
        page:
          routeParams?.tabId === UserNotesType.DC_REPORTING_DISTRICT_COMPARISON
            ? intl.formatMessage({ id: "app.titles.district" })
            : "",
      }
    );
  }, [routeParams]);
  const comparisonReportType = useMemo(() => {
    return getUrlParam(REPORT_TYPE_PARAM, location.search);
  }, [location.search]);

  const handleReturnToMainReportClick = () => {
    const { tagId, ...params } = routeParams;
    history.push({
      pathname: generatePath(AuthRoutes.YourPlanData, {
        ...params,
        workspace: "data",
      } as any),
      search:
        comparisonReportType !== null
          ? `?${REPORT_TYPE_PARAM}=${comparisonReportType}`
          : "",
    });
  }







  let surveyComparisonData: DomainQuestionsComparison | DomainComparison[] = [];
  if (surveyComparison) {
    for (let i = 0; i < surveyComparison?.length; i++) {
      if ((surveyComparison[i] as DomainComparison[])?.length === undefined) {
        surveyComparisonData = surveyComparison[i];
        break;
      }
    }
  }

  const firstSurveyData = useMemo(() => surveyComparisonData, [
    surveyComparisonData,
  ]);
  

  const isItemLevelComparison = useMemo(
    () => isDomainQuestionsComparison(firstSurveyData),
    [firstSurveyData]
  );


  const {
    surveyDepsDemographics,
    domainSchoolsDomainComparisons = [],
    itemComparisons = [],
  } = useMemo(() => {
    const surveyDepsDemographics = _.chain(surveyComparison)
      .map((domainComparisons) =>
        getSurveyDemographicForComparison(domainComparisons)
      )
      .value();

    if (isItemLevelComparison) {
      const uniqDomainResponsesByCommonText = _.chain(
        surveyComparison as DomainQuestionsComparison[]
      )
        .flatMap((c) => c.responses)
        //.uniqBy((domainResponse) => domainResponse.common_text_id)
        .uniqBy(
          (domainResponse) => domainResponse && domainResponse.common_text_id
        )
        .value();

      const itemComparisons = _.chain(uniqDomainResponsesByCommonText)
        .map((domainResponse) => {
          const questionResponses = _.chain(surveyDepsDemographics)
            .flatMap(
              (
                surveyDemographics: SurveyDemographic[],
                surveyDeploymentIndex: number
              ) => {
                if (!!surveyDemographics?.length) {
                  let questionResponseData = surveyDemographics.map(
                    (demographic) => {
                      let responseData = (surveyComparison as DomainQuestionsComparison[])?.[
                        surveyDeploymentIndex
                      ]?.responses.find(
                        (r) =>
                          domainResponse &&
                          r.common_text_id === domainResponse.common_text_id
                      )?.respondents?.[demographic];
                      if (responseData?.is_deleted) responseData = undefined;
                      return { ...responseData, ...{ demographic } };
                    }
                  );
                  return questionResponseData;
                } else return [{ demographic: "" as SurveyDemographic }];
              }
            )
            .value();

          return {
            domainResponse: domainResponse,
            questionResponses: questionResponses,
          };
        })
        .filter((r) => {
          let alldeleted = true;
          for (let i = 0; i < r.questionResponses?.length; i++) {
            if (r.questionResponses[i]?.is_deleted === false)
              alldeleted = false;
          }
          if (alldeleted) return false;
          else return true;
        })
        .value();

      let newComparisonData = [...JSON.parse(JSON.stringify(itemComparisons))];
      const lastEleLength = surveyDepsDemographics
        ? surveyDepsDemographics[surveyDepsDemographics?.length - 1]?.length
        : 0;
      if (lastEleLength != 4) {
        surveyDepsDemographics[surveyDepsDemographics.length - 1] = [
          SurveyReportTabEnum.ElementaryStudents as any,
          SurveyReportTabEnum.Students as any,
          SurveyReportTabEnum.SchoolStaff as any,
          SurveyReportTabEnum.Parents as any,
        ] as any;
      }

      newComparisonData = newComparisonData?.map((et: any) => {
        const array: any = et
          ? [...JSON.parse(JSON.stringify(et?.questionResponses))]
          : []; //[...et?.questionResponses];

        const midIndex: any = array?.length - lastEleLength;
        let secondPart: any = [...array?.slice(midIndex, array?.length)];
        let firstPart: any = [...array.slice(0, midIndex)];

        if (secondPart?.length != 4) {
          secondPart = [
            {
              demographic: SurveyReportTabEnum.ElementaryStudents,
            },
            {
              demographic: SurveyReportTabEnum.Students,
            },
            {
              demographic: SurveyReportTabEnum.SchoolStaff,
            },
            {
              demographic: SurveyReportTabEnum.Parents,
            },
          ];
        }
        // CALCULATION OF MEAN SCORE ITEM LEVEL
        for (let i in secondPart) {
          secondPart[i].distribution = [];
          let msC: any = {
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
          };
          let newArr: any = [];
          for (let j in firstPart) {
            if (parseInt(j) == 0) {
              secondPart[i].mean_score = 0;
              secondPart[i].count = 0;
              msC = {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
              };
            }
            if (firstPart[j].demographic === secondPart[i].demographic) {
              secondPart[i].count = secondPart[i].count + firstPart[j].count;
              firstPart[j]?.distribution?.map((dt: any) => {
                msC[dt.answer_index?.toString()] =
                  parseInt(msC[dt.answer_index?.toString()]) + dt.count;
              });
            }
            let total_with_count = 0;
            let total = 0;
            Object.keys(msC).map((key, index) => {
              // console.log(`${key}: ${msC[key?.toString()]}`);
              total_with_count = total_with_count +(msC[key?.toString()] * parseInt(key));
              total = total + msC[key?.toString()];
            });
            let ms = total_with_count / total;
            if (!isNaN(ms)) secondPart[i].mean_score = ms;
            else secondPart[i].mean_score = undefined;
          }
        }
        et.questionResponses = [...firstPart, ...secondPart];
        return et;
      });
      return {
        itemComparisons: newComparisonData as any,
        surveyDepsDemographics: surveyDepsDemographics,
      };
    } else {
      const uniqDomains = _.chain(surveyComparison as DomainComparison[][])
        //.flatMap((c) => c.map((d) => d.domain))
        .flatMap((c) => {
          if (!!c?.length) return c.map((d) => d.domain);
          else return [];
        })
        .uniqBy((d) => d?.id)
        .value();

      const domainSchoolsDomainComparison = _.chain(uniqDomains)
        .map((domain) => {
          const domainComparisons = _.chain(surveyDepsDemographics)
            .flatMap(
              (
                surveyDemographics: SurveyDemographic[],
                surveyDeploymentIndex: number
              ) =>
                surveyDemographics.map((demographic) => {
                  let responseData = (surveyComparison as DomainComparison[][])?.[
                    surveyDeploymentIndex
                  ]?.find((dC) => dC.domain.id === domain.id)?.respondents?.[
                    demographic
                  ];
                  return { ...responseData, ...{ demographic } };
                })
            )
            .value();

          return {
            domain: domain,
            domainComparisons: domainComparisons,
          };
        })
        .sort((a, b) => {
          let anum = 0;
          let bnum = 0;
          a.domainComparisons.forEach((data) => {
            if (!!data?.mean_score) anum++;
          });
          b.domainComparisons.forEach((data) => {
            if (!!data?.mean_score) bnum++;
          });
          if (anum === bnum) {
            return a.domain.name < b.domain.name ? -1 : 1;
          }
          return anum < bnum ? 1 : -1;
        })
        .value();

      let newComparisonData = [
        ...JSON.parse(JSON.stringify(domainSchoolsDomainComparison)),
      ];
      const lastEleLength = surveyDepsDemographics
        ? surveyDepsDemographics[surveyDepsDemographics?.length - 1]?.length
        : 0;
      if (lastEleLength != 4) {
        surveyDepsDemographics[surveyDepsDemographics?.length - 1] = [
          SurveyReportTabEnum.ElementaryStudents as any,
          SurveyReportTabEnum.Students as any,
          SurveyReportTabEnum.SchoolStaff as any,
          SurveyReportTabEnum.Parents as any,
        ] as any;
      }
      newComparisonData = newComparisonData?.map((et: any) => {
        const array: any = et
          ? [...JSON.parse(JSON.stringify(et?.domainComparisons))]
          : []; //[...et?.domainComparisons];
        const midIndex: any = array?.length - lastEleLength; //lastEleLength;
        let secondPart: any = [...array?.slice(midIndex, array?.length)];
        let firstPart: any = [...array.slice(0, midIndex)];

        if (secondPart?.length != 4) {
          secondPart = [
            {
              demographic: SurveyReportTabEnum.ElementaryStudents,
            },
            {
              demographic: SurveyReportTabEnum.Students,
            },
            {
              demographic: SurveyReportTabEnum.SchoolStaff,
            },
            {
              demographic: SurveyReportTabEnum.Parents,
            },
          ];
        }

        // CALCULATION OF MEAN SCORE
        for (let i in secondPart) {
          let msC: any = {
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
          };
          secondPart[i].distribution = [];
          let newArr: any = [];
          for (let j in firstPart) {

            if (parseInt(j) == 0) {

              secondPart[i].mean_score = 0;
              secondPart[i].total_respondents = 0;
              secondPart[i].index = 0;
              secondPart[i].count = 0;
              msC = {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
              };
            }
          

            if (firstPart[j].demographic === secondPart[i].demographic) {
              secondPart[i].total_respondents =
                secondPart[i].total_respondents +
                firstPart[j].total_respondents;
              firstPart[j]?.distribution?.map((dt: any) => {
                msC[dt.answer_index?.toString()] =
                  parseInt(msC[dt.answer_index?.toString()]) + dt.count;
              });
            }
          }
          let total_with_count = 0;
          let total = 0;
          Object.keys(msC).map((key, index) => {
            // console.log(`${key}: ${msC[key?.toString()]}`);
            total_with_count = total_with_count +(msC[key?.toString()] * parseInt(key));
            total = total + msC[key?.toString()];
          });
          let ms = total_with_count / total;
          if (!isNaN(ms)) secondPart[i].mean_score = ms;
          else secondPart[i].mean_score = undefined;
        }
        et.domainComparisons = [...firstPart, ...secondPart];
        return et;
      });
      return {
        domainSchoolsDomainComparisons: newComparisonData as any,
        surveyDepsDemographics: surveyDepsDemographics,
      };
    }
  }, [isItemLevelComparison, surveyComparison]);

  

const getItemsData=(surveyComparison:any)=>{

  const surveyDepsDemographics = _.chain(surveyComparison)
      .map((domainComparisons) =>
        getSurveyDemographicForComparison(domainComparisons)
      )
      .value();
const uniqDomainResponsesByCommonText = _.chain(
        surveyComparison as DomainQuestionsComparison[]
      )
        .flatMap((c) => c.responses)
        //.uniqBy((domainResponse) => domainResponse.common_text_id)
        .uniqBy(
          (domainResponse) => domainResponse && domainResponse.common_text_id
        )
        .value();

      const itemComparisons = _.chain(uniqDomainResponsesByCommonText)
        .map((domainResponse) => {
          const questionResponses = _.chain(surveyDepsDemographics)
            .flatMap(
              (
                surveyDemographics: SurveyDemographic[],
                surveyDeploymentIndex: number
              ) => {
                if (!!surveyDemographics?.length) {
                  let questionResponseData = surveyDemographics.map(
                    (demographic) => {
                      let responseData = (surveyComparison as DomainQuestionsComparison[])?.[
                        surveyDeploymentIndex
                      ]?.responses.find(
                        (r) =>
                          domainResponse &&
                          r.common_text_id === domainResponse.common_text_id
                      )?.respondents?.[demographic];
                      if (responseData?.is_deleted) responseData = undefined;
                      return { ...responseData, ...{ demographic } };
                    }
                  );
                  return questionResponseData;
                } else return [{ demographic: "" as SurveyDemographic }];
              }
            )
            .value();

          return {
            domainResponse: domainResponse,
            questionResponses: questionResponses,
          };
        })
        .filter((r) => {
          let alldeleted = true;
          for (let i = 0; i < r.questionResponses?.length; i++) {
            if (r.questionResponses[i]?.is_deleted === false)
              alldeleted = false;
          }
          if (alldeleted) return false;
          else return true;
        })
        .value();

      let newComparisonData = [...JSON.parse(JSON.stringify(itemComparisons))];
      const lastEleLength = surveyDepsDemographics
        ? surveyDepsDemographics[surveyDepsDemographics?.length - 1]?.length
        : 0;
      if (lastEleLength != 4) {
        surveyDepsDemographics[surveyDepsDemographics?.length - 1] = [
          SurveyReportTabEnum.ElementaryStudents as any,
          SurveyReportTabEnum.Students as any,
          SurveyReportTabEnum.SchoolStaff as any,
          SurveyReportTabEnum.Parents as any,
        ] as any;
      }

      newComparisonData = newComparisonData?.map((et: any) => {
        const array: any = et
          ? [...JSON.parse(JSON.stringify(et?.questionResponses))]
          : []; //[...et?.questionResponses];

        const midIndex: any = array?.length - lastEleLength;
        let secondPart: any = [...array?.slice(midIndex, array?.length)];
        let firstPart: any = [...array.slice(0, midIndex)];

        if (secondPart?.length != 4) {
          secondPart = [
            {
              demographic: SurveyReportTabEnum.ElementaryStudents,
            },
            {
              demographic: SurveyReportTabEnum.Students,
            },
            {
              demographic: SurveyReportTabEnum.SchoolStaff,
            },
            {
              demographic: SurveyReportTabEnum.Parents,
            },
          ];
        }
        // CALCULATION OF MEAN SCORE ITEM LEVEL
        for (let i in secondPart) {
          secondPart[i].distribution = [];
          let msC: any = {
            "1": 0,
            "2": 0,
            "3": 0,
            "4": 0,
          };
          let newArr: any = [];
          for (let j in firstPart) {
            if (parseInt(j) == 0) {
              secondPart[i].mean_score = 0;
              secondPart[i].count = 0;
              msC = {
                "1": 0,
                "2": 0,
                "3": 0,
                "4": 0,
              };
            }
            if (firstPart[j].demographic === secondPart[i].demographic) {
              secondPart[i].count = secondPart[i].count + firstPart[j].count;
              firstPart[j]?.distribution?.map((dt: any) => {
                msC[dt.answer_index?.toString()] =
                  parseInt(msC[dt.answer_index?.toString()]) + dt.count;
              });
            }
            let total_with_count = 0;
            let total = 0;
            Object.keys(msC).map((key, index) => {
              // console.log(`${key}: ${msC[key?.toString()]}`);
              total_with_count = total_with_count +(msC[key?.toString()] * parseInt(key));
              total = total + msC[key?.toString()];
            });
            let ms = total_with_count / total;
            if (!isNaN(ms)) secondPart[i].mean_score = ms;
            else secondPart[i].mean_score = undefined;
          }
        }
        et.questionResponses = [...firstPart, ...secondPart];
        return et;
      });
      return {
        itemComparisons: newComparisonData as any,
        surveyDepsDemographics: surveyDepsDemographics,
      };

}

 


  const DownloadExcelFile = () => {
 

  //Get the total reponsdents
  const on = surveyComparison.map((cd:any)=>(cd[0]?.respondents));

  const data = [
    [activePlan?.district?.name],// district name row 
    ['',..._.flatMapDeep(surveyDepsDemographics.map((sdd:any,ind:number)=> {return _.flatMapDeep(sdd.map((sd:any,index:number)=> {return index===0? selectedSurveyDeployment[ind]?.schoolName ?? '' :""}))}))], // schoolname row

    ['',..._.flatMapDeep(surveyDepsDemographics.map((sdd:any,ind:number)=> {
      
      const selectedDeployment =selectedSurveyDeployment[ind];
      
      return _.flatMapDeep(sdd.map((sd:any,index:number)=> {return index===0? selectedDeployment ?`${selectedDeployment?.deployment} Admin #${selectedDeployment?.administration}/${selectedDeployment?.month}, ${selectedDeployment?.year}` 
      :
      comparisonReportType && 'Total Mean Scores'
       :""}))
    }))], // deployment name row
    
    
    ['',..._.flatMapDeep(surveyDepsDemographics).map((s:any)=> intl.formatMessage({
      id: `app.filters.respondent-type.${s}`}))],// demographic name row

      ['Respondent Count',..._.flatMapDeep(surveyComparison.map((cd:any)=>(cd[0]?.respondents)).map((item:any,index:number)=>_.flatMapDeep(surveyDepsDemographics[index]).map((s:any)=>[item][0][s]?.total_respondents  ? [item][0][s]?.total_respondents:0))),
    
  ],// respondent count row
    
    ...domainSchoolsDomainComparisons.map((d:any)=>[d?.domain?.name,...d.domainComparisons.map((dc:any)=> dc?.mean_score?.toFixed(2)),])
  
  
  ];

  function getInitials(name: string): string {
    const matches = name.match(/(\b\S)?/g);
    return matches ? matches.join("") : "";
  }


  // Create a new workbook
  const workbook = XLSX.utils.book_new();

   // Convert the data to a worksheet
   const worksheet = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Domain Page");

  domainSchoolsDomainComparisons.map((d:any)=> { 
 
   const matchedDomain =comparisonItemsData?.filter((ci:any)=> ci?.domain?.id === d?.domain?.id)
    const itemComparisonsData = getItemsData(matchedDomain?.length ===1 && comparisonReportType ? [...matchedDomain,...matchedDomain]:matchedDomain)
   
    const itemsData=[
      [d?.domain?.name], // domain name Row
      
      ['',..._.flatMapDeep(itemComparisonsData?.surveyDepsDemographics.map((sdd:any,ind:number)=> {return _.flatMapDeep(sdd.map((sd:any,index:number)=> {return index===0? selectedSurveyDeployment[ind]?.schoolName ?? '' :""}))}))], // schoolname row

      ['',..._.flatMapDeep(itemComparisonsData?.surveyDepsDemographics.map((sdd:any,ind:number)=> {
      
        const selectedDeployment =selectedSurveyDeployment[ind];
        
        return _.flatMapDeep(sdd.map((sd:any,index:number)=> {return index===0? selectedDeployment ?`${selectedDeployment?.deployment} Admin #${selectedDeployment?.administration}/${selectedDeployment?.month}, ${selectedDeployment?.year}` 
        :
        comparisonReportType && 'Total Mean Scores'
         :""}))
      }))], // deployment name row

      ['',..._.flatMapDeep(itemComparisonsData?.surveyDepsDemographics).map((s:any)=> intl.formatMessage({
        id: `app.filters.respondent-type.${s}`}))],// demographic name row

      ['Respondent Count',..._.flatMapDeep(surveyComparison.map((cd:any)=>(cd[0]?.respondents)).map((item:any,index:number)=>_.flatMapDeep(itemComparisonsData?.surveyDepsDemographics[index]).map((s:any)=>[item][0][s]?.total_respondents  ? [item][0][s]?.total_respondents:0)))],

      ...(itemComparisonsData?.itemComparisons).map((itc:any)=>[itc?.domainResponse?.common_text,...itc?.questionResponses.map((qr:any)=>qr?.mean_score?.toFixed(2))]) // item name row
    
    ] 
    // console.log(itemsData,"<<<<<<itemsData")
    
    const itemWorksheet = XLSX.utils.aoa_to_sheet(itemsData);

   return XLSX.utils.book_append_sheet(workbook,itemWorksheet , "Items-"+getInitials((d?.domain?.name).replaceAll("and","").toUpperCase()))
  }
  )
  // // Merge cells where needed
  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 0 } }, // Merge "Demo Data District 1"
    { s: { r: 1, c: 1 }, e: { r: 1, c: 3 } }, // Merge "Mango School"
  ];

    
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to a binary string
    const binaryString = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });

    // Convert the binary string to a Blob
    const buffer = new ArrayBuffer(binaryString?.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < binaryString?.length; i++) {
      view[i] = binaryString.charCodeAt(i) & 0xff;
    }
    const blob = new Blob([buffer], { type: "application/octet-stream" });

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "data.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

const handleDownloadExcel =()=>{
  const idsArray = domainSchoolsDomainComparisons?.map((d:any)=>selectedSurveyDeployment?.map((sd)=>{return {surveyDeploymentID:sd.surveyDeploymentId,school_id:sd.schoolId ,domain:d?.domain?.id}}))

  dispatch(getComparisonDomainItemsData.request(idsArray));

}

useEffect(() => {

  if(comparisonItemsDataLoading === false){
  DownloadExcelFile()
  }

  
}, [comparisonItemsDataLoading]);



// return (
//     <>
//       <Helmet>
//         <title>{title}</title>
//       </Helmet>

//       <div className="teampage_container">
//         <div>
//           <WorkspaceHeader
//             title={title}
//             userNotesType={selectedTabId as UserNotesType}
//           />

          
//           {routeParams.domainId &&
//             routeParams?.tabId ===
//               UserNotesType.DC_REPORTING_DISTRICT_COMPARISON && (
//               <>
//                 <div className="flex justify-between mt-4">
//                   <ReturnButton
//                     text={buttonText}
//                     onClick={handleReturnToMainReportClick}
//                   />
//                 </div>
//                 <div className="flex items-center justify-between py-4">
//                   <div className="flex gap-2 items-center">
//                     <strong className="text-3xl">{selectedDomain?.name}</strong>
//                   </div>
//                 </div>
//               </>
//             )}

//           {!isDistrictActivePlan &&
//             selectedTabId !== UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS && (
//               <div className="pt-0 p-5 pb-0">
//                 {intl.formatMessage({ id: "app.collect-data.info" })}
//               </div>
//             )}
// <div className="teampage_container__tab">
//           <Tabs
//             selectedTabId={selectedTabId}
//             onChange={(tabId) => {
//               history.push(
//                 generatePath(AuthRoutes.YourPlanData, {
//                   workspace: "data",
//                   tabId: tabId,
//                 } as any)
//               );
//             }}
//             renderActiveTabPanelOnly
//           >
//             {!isDistrictActivePlan && (
//               <Tab
//                 id={UserNotesType.DC_REPORTING_ADMINISTRATION_GUIDELINES}
//                 className="text-xl"
//                 panel={<AdministrationGuidelinesTab />}
//               >
//                 {intl.formatMessage({
//                   id: "app.collect-data.administration-guidelines",
//                 })}
//               </Tab>
//             )}
//             <Tab
//               id={UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS}
//               className="text-xl"
//               panel={<SurveyDataTab />}
//             >
//               {intl.formatMessage({
//                 id: "app.collect-data.survey-deployments",
//               })}
//             </Tab>
//             {isDistrictActivePlan && (
//               <Tab
//                 id={UserNotesType.DC_REPORTING_DISTRICT_COMPARISON}
//                 className="text-xl"
//                 panel={<DistrictComparisonTab />}
//                 disabled={!selectedSurveyDeployment.length}
//               >
//                 <Popover
//                   popoverClassName="w-25"
//                   interactionKind={"hover"}
//                   disabled={!!selectedSurveyDeployment.length}
//                   content={
//                     <div className={"p-3"}>
//                       {intl.formatMessage(
//                         {
//                           id:
//                             "app.collect-data.district-comparison.tab-disabled.popover",
//                         },
//                         { i: (chunks) => <i>{chunks}</i> }
//                       )}
//                     </div>
//                   }
//                 >
//                   {intl.formatMessage({
//                     id: "app.collect-data.district-comparison",
//                   })}
//                 </Popover>
//               </Tab>
//             )}
//             {/* {!isDistrictActivePlan && isStrategicPlanEnabled && (
//               <Tab
//                 id={UserNotesType.DC_REPORTING_SUPPLEMENTAL_DATA}
//                 className="text-xl"
//                 panel={<AddAdditionalDataForm isReadonly={isTeamMember} />}
//               >
//                 {intl.formatMessage({
//                   id: "app.collect-data.additional-data",
//                 })}
//               </Tab>
//             )} */}
//             <Tab
//               id={UserNotesType.DC_REPORTING_RESOURCES}
//               title={intl.formatMessage({ id: "app.titles.resources" })}
//               panel={
//                 <ActivityResourcesTab
//                   activityTag={ActivityTagEnum.DATA}
//                   userNotesType={UserNotesType.DC_REPORTING_RESOURCES}
//                 />
//                 //   <ActivityResourcesTab
//                 //   userNotesType={
//                 //     isDistrictActivePlan
//                 //       ? UserNotesType.DISTRICT_RESOURCES_WORKSPACE
//                 //       : UserNotesType.SCHOOL_RESOURCES_WORKSPACE
//                 //   }
//                 // />
//               }
//             />
             
//   <Button
//             disabled={false}
//             className="absolute top-0 right-0"
//             intent="primary"
//             icon="download"
//             title={intl.formatMessage({
//               id: "app.survey-report.breadcrumb.download-spreadsheet",
//             })}
//             text={intl.formatMessage({
//               id: "app.survey-report.breadcrumb.download-spreadsheet",
//             })}
//              onClick={handleDownloadExcel}
//             // loading={loading}
//           />

//           </Tabs>

   










//           </div>
//         </div>
//       </div>

//       <SurveyDeploymentDialog isTeamPlanDialog />
//       <CreateCustomDataSetDialog />
//       <CreateDatasetFromFileDialog />
//       <SurveyUpsertDialog />
//       <CoordinateSurveyAdministrationDialog
//         isOpen={!isFirstTimeDialogWatched && isDistrictActivePlan}
//         onClose={handleCoordinateSurveyAdministrationClose}
//       />


//     </>

    
//   );  

return (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>

    <div className="teampage_container">
      <div>
        <WorkspaceHeader
          title={title}
          userNotesType={selectedTabId as UserNotesType}
        />

        {routeParams.domainId &&
          routeParams?.tabId ===
            UserNotesType.DC_REPORTING_DISTRICT_COMPARISON && (
            <>
              <div className="flex justify-between mt-4">
                <ReturnButton
                  text={buttonText}
                  onClick={handleReturnToMainReportClick}
                />
              </div>
              <div className="flex items-center justify-between py-4">
                <div className="flex gap-2 items-center">
                  <strong className="text-3xl">{selectedDomain?.name}</strong>
                </div>
              </div>
            </>
          )}

        {!isDistrictActivePlan &&
          selectedTabId !== UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS && (
            <div className="pt-0 p-5 pb-0">
              {/* {intl.formatMessage({ id: "app.collect-data.info" })} */}
            </div>
          )}
        <div className="teampage_container__tab">
          <div className="flex items-center justify-between">

          <Tabs
            selectedTabId={selectedTabId}
            onChange={(tabId) => {
              history.push(
                generatePath(AuthRoutes.YourPlanData, {
                  workspace: "data",
                  tabId: tabId,
                } as any)
              );
            }}
            renderActiveTabPanelOnly
          >
            {!isDistrictActivePlan && (
              <Tab
                id={UserNotesType.DC_REPORTING_ADMINISTRATION_GUIDELINES}
                className="text-xl"
                panel={<AdministrationGuidelinesTab />}
              >
                {intl.formatMessage({
                  id: "app.collect-data.administration-guidelines",
                })}
              </Tab>
            )}
            <Tab
              id={UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS}
              className="text-xl"
              panel={<SurveyDataTab />}
            >
              {intl.formatMessage({
                id: "app.collect-data.survey-deployments",
              })}
            </Tab>
            {isDistrictActivePlan ? (
              <Tab
                id={UserNotesType.DC_REPORTING_DISTRICT_COMPARISON}
                className="text-xl"
                panel={<DistrictComparisonTab />}
                disabled={!selectedSurveyDeployment.length}
              >
                <Popover
                  popoverClassName="w-25"
                  interactionKind={"hover"}
                  disabled={!!selectedSurveyDeployment.length}
                  content={
                    <div className={"p-3"}>
                      {intl.formatMessage(
                        {
                          id:
                            "app.collect-data.district-comparison.tab-disabled.popover",
                        },
                        { i: (chunks) => <i>{chunks}</i> }
                      )}
                    </div>
                  }
                >
                  {intl.formatMessage({
                    id: "app.collect-data.district-comparison",
                  })}
                </Popover>
              </Tab>
            )
          :
          (
            <Tab
              id={UserNotesType.DC_REPORTING_DISTRICT_COMPARISON}
              className="text-xl"
              panel={<DistrictComparisonTab />}
              disabled={!selectedSurveyDeployment.length}
            >
              <Popover
                popoverClassName="w-25"
                interactionKind={"hover"}
                disabled={!!selectedSurveyDeployment.length}
                content={
                  <div className={"p-3"}>
                   {intl.formatMessage({id:"app.school-surveys-comparison.tab.info"},
                     { i: (chunks) => <i>{chunks}</i> })}
                  </div>
                }
              >
                {intl.formatMessage({
                    id: "app.collect-data.school-comparison",
                  })}
              </Popover>
            </Tab>
          )
          
          }
            {/* {!isDistrictActivePlan && isStrategicPlanEnabled && (
              <Tab
                id={UserNotesType.DC_REPORTING_SUPPLEMENTAL_DATA}
                className="text-xl"
                panel={<AddAdditionalDataForm isReadonly={isTeamMember} />}
              >
                {intl.formatMessage({
                  id: "app.collect-data.additional-data",
                })}
              </Tab>
            )} */}
            <Tab
              id={UserNotesType.DC_REPORTING_RESOURCES}
              title={intl.formatMessage({ id: "app.titles.resources" })}
              panel={
                <ActivityResourcesTab
                  activityTag={ActivityTagEnum.DATA}
                  userNotesType={UserNotesType.DC_REPORTING_RESOURCES}
                />
                //   <ActivityResourcesTab
                //   userNotesType={
                //     isDistrictActivePlan
                //       ? UserNotesType.DISTRICT_RESOURCES_WORKSPACE
                //       : UserNotesType.SCHOOL_RESOURCES_WORKSPACE
                //   }
                // />
              }
            />
             
             {selectedTabId === UserNotesType.DC_REPORTING_DISTRICT_COMPARISON && isDistrictActivePlan && (
              !isItemLevelComparison ? 
              (comparisonItemsDataLoading ?
              <Tag className="absolute top-0 right-0" intent="primary" large>
              Allow a few minutes for download
            </Tag> :<Button
                disabled={false}
                hidden={!activePlan?.district?.is_dummy_district}
                className="absolute top-0 right-0"
                intent="primary"
                icon="download"
                title={intl.formatMessage({
                  id: "app.survey-report.breadcrumb.download-spreadsheet",
                })}
                text={intl.formatMessage({
                  id: "app.survey-report.breadcrumb.download-spreadsheet",
                })}
                onClick={handleDownloadExcel}
              />)
              :""
            )}


          </Tabs>





      
         
          </div>
        </div>
      </div>
    </div>

    <SurveyDeploymentDialog isTeamPlanDialog />
    <CreateCustomDataSetDialog />
    <CreateDatasetFromFileDialog />
    <SurveyUpsertDialog />
    <CoordinateSurveyAdministrationDialog
      isOpen={!isFirstTimeDialogWatched && isDistrictActivePlan}
      onClose={handleCoordinateSurveyAdministrationClose}
    />
  </>
);



};

export default CollectDataPage;
