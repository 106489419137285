import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import {
  closeSurveyDeployment,
  getSurveysDeployment,
  setSelectedDistrictComarprisonSurvey,
  setSelectedSurveyDeployment,
  showSurveyDeploymentDialog,
} from "../../../../store/surveys-deployment/actions";
import { useLoading } from "../../../../helpers/hooks/useLoading";
import { CellProps } from "react-table";
import {
  DemographicWrapper,
  District,
  School,
  SchoolNameDeplyments,
  SchoolNameDeplymentTarget,
  SurveyDemographic,
  SurveyDeploymentDistrictComparison,
  SurveyDeploymentStateEnum,
  SurveyDeploymentYear,
  SurveyReportTabEnum,
  SurveyTableItem,
  UserNotesType,
} from "../../../../types";
import {
  Button,
  Divider,
  Icon,
  Menu,
  MenuItem,
  NonIdealState,
  Popover,
  PopoverInteractionKind,
  Tag,
  Tooltip,
} from "@blueprintjs/core";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import { AppToaster } from "../../../../helpers/toaster";
import EntityTable from "../../../common/EntityTable";
import SurveyDeploymentResponsesDialog from "../../dialogs/SurveyDeploymentResponsesDialog";
import { useHistory } from "react-router-dom";
import { generatePath } from "react-router";
import { AuthRoutes } from "../../../../App";
import SampleLettersDialog from "../../dialogs/SampleLettersDialog";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import useSurveyDeploymentDelete from "../../../../helpers/hooks/surveys-deployment/useSurveyDeploymentDelete";
import { SurveyDeploymentTableActions } from "./SurveyDeploymentTableActions";
import useSchoolPlanDemographics from "../../../../helpers/hooks/useSchoolPlanDemographics";
import { getAvailableDemographics } from "../../../../helpers/survey-deployment-utils";
import SurveyOpenPeriodDateCell from "./SurveyOpenPeriodDateCell";
import ComparisonReportDialog from "../../dialogs/ComparisonReportDialog";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import { actionAuditselectSurveyDeployment } from "../../../../store/survey-reports/actions";
import moment from "moment";
import { selectIsAdmin } from "../../../../store/auth/selectors";
import _, { filter } from "lodash";

type OwnProps = {
  darkHeader?: boolean;
  isTeamPlanTable?: boolean;
  isManagedDeletedDataWorkspace?: boolean;
  showSdDialog?: boolean;
  id?:string;
};

type Props = OwnProps;

type DialogProps = {
  surveyDeploymentId: number;
  surveyName?: string;
  startDate?: string;
  endDate?: string;
  districts?: District[];
  isReady?: boolean;
  surveyDeploymentSchoolId?: number;
};

export const REPORT_TYPE_PARAM = "report_type";

const SurveysDeploymentTable: React.FC<Props> = ({
  darkHeader,
  isTeamPlanTable,
  isManagedDeletedDataWorkspace,
  showSdDialog,
  id
}: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { hasDataCoordinatorAccess, hasTeamChampionAccess } = useUserRole();
  const JUNE_MONTH = 6;
  const activePlan = useSelector((s) =>
    isTeamPlanTable ? s.plans.activePlan : undefined
  );

  const isDistrictActivePlan = useSelector(isDistrictPlan);
  const isAdmin = useSelector(selectIsAdmin);

  const history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const [
    isComparisonReportDialogOpen,
    setComparisonReportDialogOpen,
  ] = useState<boolean>(false);

  const [isDownloadDialogOpen, setDownloadDialogOpen] = useState<boolean>(
    false
  );

  const { demographics } = useSchoolPlanDemographics({
    grades: activePlan?.school?.grades,
  });

  const [dialogProps, setDialogProps] = useState<DialogProps>();
  
  const selectedDistrict = useSelector((s) => s.plans.activePlan?.district);
  const selectedSurveyDeployment = useSelector(
    (s) => s.surveysDeployment.selectedDistrictComarprisonSurvey
  );

  const loading = useSelector(
    (s) => s.surveysDeployment.loading.getSurveysDeployment
  );
  const error = useSelector(
    (s) => s.surveysDeployment.errors.getSurveysDeployment
  );
  useLoading({ loading, error });

const text= intl.formatMessage({
  id: `app.confirm-dialog${
    isManagedDeletedDataWorkspace ? ".manage-deleted" : ""
  }.survey-deployment-soft-delete.first-step`,
},{ b: (chunks) => <b>{chunks}</b> })
 

const {
    deleteLoading,
    processSurveyDeploymentDelete,
  } = useSurveyDeploymentDelete(
    isManagedDeletedDataWorkspace,
    isDistrictActivePlan,
    text
  );

  const closeLoading = useSelector(
    (s) => s.surveysDeployment.loading.closeSurveyDeployment
  );
  const closeError = useSelector(
    (s) => s.surveysDeployment.errors.closeSurveyDeployment
  );
  const addSD = useSelector(
    (s) => s.surveysDeployment.loading.addSurveyDeployment
  );
  const onCloseSuccess = useCallback(() => {
    AppToaster.show({
      message: intl.formatMessage({
        id: "app.toaster.surveys-deployment.closed",
      }),
      icon: "tick",
      intent: "success",
    });
  }, []);
  useLoading({
    loading: closeLoading,
    error: closeError,
    onSuccess: onCloseSuccess,
  });

  const surveysDeployment = useSelector(
    (s) => s.surveysDeployment.surveysDeployment
  );
  const COMPARISON_REPORT_CHECK_BOX: string = "Comparison Report Check Boxes";
  const COMPARISON_1: string = "Comparison Report #1";
  const COMPARISON_2: string = "Comparison Report #2";

  const surveysDeploymentData: SurveyTableItem[] = useMemo(() => {
    let surveyTableItems: SurveyTableItem[] = [];
    surveysDeployment.forEach((sd) => {
      let school: School | undefined;
      sd?.school_open_periods?.map((d) => {
        school = d.school;
      });
      surveyTableItems.push({
        school: school,
        administration: sd.deployment_name
          ? parseInt(sd.deployment_name.split("#")[1])
          : 0,
        deployment: sd.deployment_name ? sd.deployment_name.split(" ")[0] : "",
        year: moment(sd.end_date).format("YYYY"),
        month: moment(sd.end_date).format("MMMM"),
        ...sd,
      });
    });
    return surveyTableItems;
  }, [surveysDeployment]);

  const [distinctSchoolYear, distinctSurveyDeployments] = useMemo(() => {
    let schoolYear: string[] = [];
    let surveyDeployments: SurveyDeploymentYear[] = [];
    surveysDeploymentData.forEach((sd) => {
      if (sd.state === SurveyDeploymentStateEnum.READY) {
        if (
          sd.deployment !== undefined &&
          schoolYear.indexOf(sd.deployment) === -1
        ) {
          schoolYear.push(sd.deployment);
        }

        if (
          surveyDeployments.findIndex(
            (x: any) =>
              x.deployment === sd.deployment &&
              x.administration === sd.administration
          ) === -1
        ) {
          surveyDeployments.push({
            deployment: sd.deployment,
            administration: sd.administration,
          });
        }
      }
    });
    return [schoolYear, surveyDeployments];
  }, [surveysDeploymentData]);

  useEffect(() => {
    if ((isTeamPlanTable && activePlan?.id) || !isTeamPlanTable || !addSD) {
      dispatch(
        getSurveysDeployment.request({
          planId: isTeamPlanTable ? activePlan?.id : undefined,
          isDeleted: isManagedDeletedDataWorkspace,
        })
      );
    }
  }, [activePlan?.id, isTeamPlanTable, isManagedDeletedDataWorkspace, addSD]);

  const showConfirmCloseDialog = (surveyDeploymentId: number) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          onSurveyDeploymentCloseConfirmed(surveyDeploymentId);
        },
        show: true,
        intent: "primary",
        text: intl.formatMessage(
          {
            id: "app.confirmation-dialogs.close-survey-deployment",
          },
          {
            br: <br />,
          }
        ),
        icon: "info-sign",
        confirmButtonText: intl.formatMessage({
          id: "app.survey-deployments.close-confirm.generate-reports",
        }),
        cancelButtonText: intl.formatMessage({
          id: "app.titles.no",
        }),
      })
    );
  };

  const onSurveyDeploymentCloseConfirmed = (surveyDeploymentId: number) => {
    dispatch(hideConfirmDialog());
    dispatch(
      closeSurveyDeployment.request({
        surveyDeploymentId: surveyDeploymentId,
        planId: activePlan?.id!,
      })
    );
  };

  const [searchString, setSearchString] = useState("");

  const handleResponseClick = (
    surveyDeploymentId: number,
    responses: DemographicWrapper<number>,
    districts?: District[],
    isReady?: boolean,
    surveyDeploymentSchoolId?: number
  ) => {
    setIsDialogOpen(true);
    setDialogProps({
      surveyDeploymentId: surveyDeploymentId,
      districts: districts,
      isReady: isReady,
      surveyDeploymentSchoolId: surveyDeploymentSchoolId,
    });
  };

  const handleViewReportsClick = (
    surveyDeploymentId: number,
    schoolId?: number
  ) => {
    history.push(
      generatePath(AuthRoutes.YourPlanReports, {
        workspace: "report",
        tabId: SurveyReportTabEnum.ResponseRates,
        surveyDeploymentId: surveyDeploymentId,
        schoolId: schoolId,
      } as any)
    );
  };

  const handleDownloadLettersClick = (surveyDeploymentId: number) => {
    setDownloadDialogOpen(true);
    setDialogProps({ surveyDeploymentId: surveyDeploymentId });
  };

  const handleChangeReportDialog = (
    value: string | SurveyDeploymentYear,
    comparisonReportType: string
  ) => {
    let sd: SurveyDeploymentDistrictComparison[] = [];
    surveysDeploymentData.forEach((el) => {
      if (
        (typeof value == "string"
          ? el.deployment === value
          : el.deployment === value.deployment &&
          el.administration === value.administration) &&
        el.state === SurveyDeploymentStateEnum.READY
      ) {
        sd.push({
          surveyDeploymentId: el.id,
          schoolId: el.school?.id || -1,
          schoolName: el.school?.name,
          deployment: el.deployment,
          administration: el.administration,
          year: el.year,
          month: el.month,
          responses: el.responses,
        });
      }
    });
    if (typeof value == "string") {
      dispatch(
        actionAuditselectSurveyDeployment.request({
          event: COMPARISON_2,
          targets: value,
        })
      );
    } else {
      dispatch(
        actionAuditselectSurveyDeployment.request({
          event: COMPARISON_1,
          targets: `${value.deployment} Administration #${value.administration}`,
        })
      );
    }

    if (sd.length > 0) {
      dispatch(setSelectedDistrictComarprisonSurvey(sd));
      navigateToDistrictComparisonTab(comparisonReportType);
    }
  };

  const handleSelectedChange = (data: SurveyTableItem[]) => {
    let sd: SurveyDeploymentDistrictComparison[] = [];
    data.forEach((el, i) => {
      if (el && el.school && el.state === SurveyDeploymentStateEnum.READY) {
        sd.push({
          surveyDeploymentId: el.id,
          schoolId: el.school.id,
          schoolName: el.school.name,
          deployment: el.deployment,
          administration: el.administration,
          year: el.year,
          month: el.month,
          responses: el.responses,
        });
      }
    });
    dispatch(setSelectedDistrictComarprisonSurvey(sd));
  };

  const surveyDeploymentselected: SchoolNameDeplyments = useMemo(() => {
    const SchoolNameDeplyment: SchoolNameDeplymentTarget = {
      event: COMPARISON_REPORT_CHECK_BOX,
      targets: [],
    };
    if (selectedSurveyDeployment)
      selectedSurveyDeployment.map((el: SurveyDeploymentDistrictComparison) => {
        SchoolNameDeplyment.targets.push({
          deploymentYear: `${el?.deployment} Administration #${el?.administration}`,
          school: el?.schoolName,
        });
      });

    return SchoolNameDeplyment;
  }, [selectedSurveyDeployment]);

  const navigateToDistrictComparisonTab = (comparisonReportType: string) => {
    if (comparisonReportType === "") {
      dispatch(
        actionAuditselectSurveyDeployment.request(surveyDeploymentselected)
      );
    }
    history.push({
      pathname: generatePath(AuthRoutes.YourPlanData, {
        workspace: "data",
        tabId: UserNotesType.DC_REPORTING_DISTRICT_COMPARISON,
      } as any),
      search:
        comparisonReportType !== ""
          ? `?${REPORT_TYPE_PARAM}=${comparisonReportType}`
          : "",
    });
  };

  const isCheckBoxDisable = (state: string) => {
    return state !== SurveyDeploymentStateEnum.READY
      ? { checked: false, disabled: true }
      : {};
  };

  let mostRecentDeployment = surveysDeployment.find((sd) => {
    if (
    sd.state === SurveyDeploymentStateEnum.READY &&
    sd.is_deleted === false
    ) {
    return sd;
    }
    });

  const columns: any = useMemo(() => {
    const tableColumns = [
      {
        Header: intl.formatMessage({
          id: "app.surveys-deployment-table.columns.districts",
        }),
        accessor: "districts",
        hidden: isTeamPlanTable,
        width: "15%",
        Cell: ({
          row: { original },
        }: CellProps<
          SurveyTableItem & {
            availableDemographics: SurveyDemographic[];
          }
        >) => {
          return (
            <div className="flex justify-center flex-wrap">
              {original.districts?.length ? (
                original.districts.map((item) => (
                  <Tag key={`district_${item.id}`} className="m-1">
                    {item.name}
                  </Tag>
                ))
              ) : (
                <Tag className="m-1">
                  {intl.formatMessage({
                    id: "app.surveys-deployment-table.columns.districts.all",
                  })}
                </Tag>
              )}
            </div>
          );
        },
      },
      {
        Header: intl.formatMessage({
          id: "app.surveys-deployment-table.columns.survey",
        }),
        accessor: "survey.name",
        width: "100%",
        Cell: ({
          row: { original },
        }: CellProps<
          SurveyTableItem & {
            availableDemographics: SurveyDemographic[];
          }
        >) => <p className="font-bold">{original?.survey?.name}</p>,
      },
      {
        id: "created_at",
        Header: intl.formatMessage({
          id: "app.surveys-deployment-table.columns.date-created",
        }),
        width: "100%",
        Cell: ({
          row: { original },
        }: CellProps<
          SurveyTableItem & {
            availableDemographics: SurveyDemographic[];
          }
        >) => (
          <SurveyOpenPeriodDateCell
            surveyDeployment={original}
            isTeamPlanTable={isTeamPlanTable}
            dateField={"created_at"}
          />
        ),
      },
      {
        id: "deployment",
        Header: intl.formatMessage({
          id: "app.surveys-deployment-table.columns.deployment",
        }),
        width: "20%",
        Cell: ({
          row: { original },
        }: CellProps<
          SurveyTableItem & {
            availableDemographics: SurveyDemographic[];
          }
        >) => (
          <>
            <p className="text-center">{original?.deployment_name}{original?.is_deleted && <b>-del</b>}</p>


            {/* <p className="text-center">{original?.deployment}</p>
            <p className="whitespace-no-wrap -mx-2">
              {intl.formatMessage(
                {
                  id: "app.surveys-deployment-table.deployment.administration",
                },
                { count: original?.administration }
              )}
            </p> */}
          </>
        ),
      },
      {
        id: "school_name",
        Header: intl.formatMessage({
          id: "app.schools.upsert-dialog.school-name",
        }),
        width: "100%",
        Cell: ({
          row: { original },
        }: CellProps<
          SurveyTableItem & {
            availableDemographics: SurveyDemographic[];
          }
        >) => <p>{original.school?.name}</p>,
      },
      {
        Header: intl.formatMessage({
          id: "app.titles.stakeholder-group",
        }),
        width: "100%",
        hidden: !isTeamPlanTable,
        Cell: ({
          row: {
            original: { availableDemographics },
          },
        }: CellProps<
          SurveyTableItem & {
            availableDemographics: SurveyDemographic[];
          }
        >) => {
          return (
            <>
              {availableDemographics.map((d, i) => (
                <div key={i}>
                  <p className="whitespace-no-wrap -mx-2">
                    {intl.formatMessage({
                      id: `app.filters.respondent-type.${d}`,
                    })}
                  </p>
                  {i !== availableDemographics.length - 1 ? (
                    <Divider className="-mx-4" />
                  ) : null}
                </div>
              ))}
            </>
          );
        },
      },
      {
        id: "responses",
        Header: () => (
          <div className="flex items-center space-x-2">
            <div>
              {intl.formatMessage({
                id: "app.surveys-table.columns.responses",
              })}
            </div>
            <Popover
              popoverClassName="w-14"
              interactionKind={"hover"}
              content={
                <div className={"p-2"}>
                  {intl.formatMessage({
                    id: "app.surveys-table.columns.responses.info",
                  })}
                </div>
              }
              target={<Icon className="cursor-pointer" icon="info-sign" />}
            />
          </div>
        ),
        width: "100%",
        Cell: ({
          row: { original },
        }: CellProps<
          SurveyTableItem & {
            availableDemographics: SurveyDemographic[];
          }
        >) => {
          const responsesDemographics = Object.keys(original?.responses ?? {});
          const { availableDemographics } = original;

          return (
            <div
              className={
                !isTeamPlanTable ? `flex flex-wrap` : ""
              }
              onClick={() => {
                !showSdDialog &&
                  handleResponseClick(
                    original?.id,
                    original?.responses,
                    original?.districts,
                    original?.state === SurveyDeploymentStateEnum.READY,
                    isTeamPlanTable && original.school_open_periods?.length
                      ? original.school_open_periods[0].school?.id
                      : undefined
                  );
              }}
            >
              {isTeamPlanTable ? (
                <>
                  {availableDemographics.map((d, i) => {
                    const demographicResponse = original?.responses?.[d];
                    return (
                      <div key={i}>
                        {demographicResponse ? (
                          <p className="flex justify-center">
                            {demographicResponse}
                          </p>
                        ) : (
                          <div className="font-bold flex justify-center">
                            &#8722;
                          </div>
                        )}
                        {i !== availableDemographics.length - 1 ? (
                          <Divider className="-mx-4" />
                        ) : null}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  {responsesDemographics?.map((demographic) => {
                    const demographicResponse =
                      original?.responses?.[demographic as SurveyDemographic];
                    return (
                      <div key={demographic} className="flex-no-wrap">
                        <strong>{demographicResponse}</strong>
                        &nbsp;
                        {intl.formatMessage({
                          id: `app.filters.respondent-type.${demographic}${demographicResponse === 1 ? ".single" : ""
                            }`,
                        })}
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          );
        },
      },
      {
        id: "start_date",
        Header: intl.formatMessage({
          id: "app.surveys-deployment-table.columns.start-date",
        }),
        width: "100%",
        Cell: ({
          row: { original },
        }: CellProps<
          SurveyTableItem & {
            availableDemographics: SurveyDemographic[];
          }
        >) => (
          <SurveyOpenPeriodDateCell
            surveyDeployment={original}
            isTeamPlanTable={isTeamPlanTable}
            dateField={"start_date"}
          />
        ),
      },
      {
        id: "end_date",
        Header: (
          <>
            {intl.formatMessage({
              id: "app.surveys-deployment-table.columns.end-date",
            })}
            {isTeamPlanTable && (
              <Tooltip
                className="ml-2"
                popoverClassName={"max-w-80"}
                content={
                  <p>
                    {intl.formatMessage(
                      {
                        id:
                          "app.surveys-deployment-table.columns.end-date.info",
                      },
                      { i: (chunks) => <i>{chunks}</i> }
                    )}
                  </p>
                }
              >
                <Icon icon={"info-sign"} className="cursor-pointer" />
              </Tooltip>
            )}
          </>
        ),
        width: "100%",
        Cell: ({
          row: { original },
        }: CellProps<
          SurveyTableItem & {
            availableDemographics: SurveyDemographic[];
          }
        >) => {
          return (
            <SurveyOpenPeriodDateCell
              surveyDeployment={original}
              isTeamPlanTable={isTeamPlanTable}
              dateField={"end_date"}
            />
          );
        },
      },
      {
        id: "actions",
        width: "100%",
        hidden: showSdDialog,
        Header: intl.formatMessage({ id: "app.surveys-table.columns.actions" }),
        Cell: ({
          row: { original },
        }: CellProps<
          SurveyTableItem & {
            availableDemographics: SurveyDemographic[];
          }
        >) => {
          return (
            <SurveyDeploymentTableActions
            isLatestSD={mostRecentDeployment && (mostRecentDeployment.id === original.id)}
              isAdminOrDistrictActivePlan={isDistrictActivePlan}
              surveyDeployment={original}
              forTeamPlanView={isTeamPlanTable}
              onEditSurveyDeployment={() => {
                dispatch(setSelectedSurveyDeployment(original));
                dispatch(showSurveyDeploymentDialog());
              }}
              onDeleteSurveyDeployment={(e: React.MouseEvent<HTMLElement>) =>
                processSurveyDeploymentDelete(
                  original.id,
                  (isTeamPlanTable || hasTeamChampionAccess)
                )
              }
              onGenerateReports={(e: React.MouseEvent<HTMLElement>) =>
                showConfirmCloseDialog(original.id)
              }
              onViewReports={(e: React.MouseEvent<HTMLElement>) => {
                handleViewReportsClick(original.id, original.school?.id);
              }}
              onDownloadLetters={(e: React.MouseEvent<HTMLElement>) =>
                handleDownloadLettersClick(original.id)
              }
              isManagedDeletedDataWorkspace={isManagedDeletedDataWorkspace}
            />
          );
        },
      },
    ];
    return tableColumns.filter((tc) => !tc.hidden);
  }, [intl, isTeamPlanTable, showSdDialog, isDistrictActivePlan, isManagedDeletedDataWorkspace, dispatch, processSurveyDeploymentDelete, hasTeamChampionAccess, showConfirmCloseDialog, handleViewReportsClick]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const surveysDeploymentFilter = (surveyDeployment: SurveyTableItem) => {
    const searchStringLowerCase = searchString.trim().toLowerCase();

    if (!searchStringLowerCase) {
      return true;
    }

    const availableDemographics = getAvailableDemographics({
      availableDemographicsInPlan: demographics,
      schoolOpenPeriods: surveyDeployment.school_open_periods,
      responses: surveyDeployment.responses,
    });

    let surveyResponses = surveyDeployment.responses;
    const openPeriods = surveyDeployment.school_open_periods;

    const isMatched = (value: string | number | undefined): boolean | string =>
      value !== undefined ? value.toString().toLowerCase().includes(searchStringLowerCase) : false;

    return (
      isMatched(surveyDeployment?.school?.name) ||
      isMatched(surveyDeployment.deployment_name) ||
      openPeriods
        // eslint-disable-next-line array-callback-return
        ?.some(open_periods => {
          if (open_periods.start_date && open_periods.end_date) {
            const start = moment(open_periods.start_date).format("MM/DD/YY");
            const end = moment(open_periods.end_date).format("MM/DD/YY");
            return isMatched(start) || isMatched(end);
          }
        }) ||
      availableDemographics?.filter(d =>
        isMatched(intl.formatMessage({ id: `app.filters.respondent-type.${d}` }))
      ).length > 0 ||
      Object.values(surveyResponses).includes(Number(searchStringLowerCase)) ||
      isMatched(surveyDeployment?.survey?.name) ||
      surveyDeployment.districts?.some(district =>
        isMatched(district.name)
      )
    );
  };

  const filteredData: (SurveyTableItem & {
    availableDemographics: SurveyDemographic[];
  })[] = useMemo(
    () =>
      surveysDeploymentData
        .filter(surveysDeploymentFilter)
        // .filter((item) => !item.is_deleted)
        .map((el: SurveyTableItem) => {
          const availableDemographics = getAvailableDemographics({
            availableDemographicsInPlan: demographics,
            schoolOpenPeriods: el.school_open_periods,
            responses: el.responses,
          });

          return { ...el, availableDemographics: availableDemographics };
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [surveysDeploymentData, demographics, searchString]
  );

  const handleCreateSurveyDeploymentClick = () => {
    dispatch(showSurveyDeploymentDialog());
  };

  const getResponses = (responses: object) => {
    let responsesCount = "";
    for (const [key, value] of Object.entries(responses)) {
      responsesCount = responsesCount + `${intl.formatMessage({ id: `app.surveys.survey-demographic.${key}` })}: ${value}, `;
    }
    return responsesCount;
  };
  const handleCsvDownload = () => {
    const updatedData = filteredData.map((f) => {
      return {
        ...f,
        created_at: moment(f.created_at).format("MM/DD/YYYY"),
        districts: f.districts ? f?.districts?.find((d) => d)?.name : "",
        survey: f.survey.name,
        school: f?.school?.name,
        responses: getResponses(f?.responses),
      };
    });

    const header = [
      "Districts",
      "Survey Name",
      "Date Created",
      "Deployment",
      "school Name",
      "count",
      "start dates",
      "end dates",
    ];
    const dataRows = updatedData.map((u) =>
      _.chain(u)
        .pick([
          "districts",
          "survey",
          "created_at",
          "deployment_name",
          "school",
          "responses",
          "start_date",
          "end_date",
        ])
        .values()
        .map((val: string) => {
          if (val && val.toString().indexOf(",") >= 0) {
            return `"${val.toString().replaceAll('"', '\\"')}"`;
          } else {
            return val;
          }
        })
        .join(",")
    );

    const csvStr = [header, ...dataRows].join("\n");
    const blob = new Blob([csvStr], { type: "text/csv;charset=utf-8;" });
    const csvUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute(
      "download",
      `NJ SCI Survey Deployment Export ${moment().format("MM/DD/YYYY")}`
    );
    link.href = csvUrl;
    document.body.append(link);
    link.click();
    link.remove();
  };

  const popoverContent = () => {
    return (
      <Menu>
        <MenuItem
          text={intl.formatMessage({
            id: "app.survey-deployment.create-button",
          })}
          icon="plus"
          className="px-3 capitalize"
          onClick={handleCreateSurveyDeploymentClick}
        />
        <MenuItem
          text={intl.formatMessage({
            id: "app.titles.download-as-CSV",
          })}
          icon="import"
          className="px-3 capitalize"
          onClick={handleCsvDownload}
        />
      </Menu>
    );
  };

  const nonIdealState = useMemo(
    () => (
      <NonIdealState
        icon="search"
        className="pb-2"
        title={intl.formatMessage({
          id: isTeamPlanTable
            ? isManagedDeletedDataWorkspace
              ? "app.survey-deployments.manage-deleted-data.title"
              : "app.teaming.survey-deployment-table.non-ideal-state.title"
            : "app.surveys-deployment-table.no-data",
        })}
        description={intl.formatMessage({
          id: isTeamPlanTable
            ? isManagedDeletedDataWorkspace
              ? "app.survey-deployments.manage-deleted-data.content"
              : "app.teaming.survey-deployment-table.non-ideal-state.description"
            : "app.surveys-deployment-table.try-to-create-new-survey-deployment",
        })}
        action={
          isTeamPlanTable ? undefined : (
            <Button
              text={intl.formatMessage({
                id: "app.survey-deployment.create-button",
              })}
              title={intl.formatMessage({
                id: "app.survey-deployment.create-button",
              })}
              icon="plus"
              intent="primary"
              onClick={handleCreateSurveyDeploymentClick}
            />
          )
        }
      />
    ),
    [isTeamPlanTable, isManagedDeletedDataWorkspace]
  );

  const initialState = useMemo(() => {
    const selectSchoolObject = {};
    filteredData.forEach(({ id }, index: number) => {
      if (
        selectedSurveyDeployment.findIndex(
          (x) => x.surveyDeploymentId === id
        ) !== -1 && showSdDialog
      ) {
        Object.assign(selectSchoolObject, { [index]: true });
      } else Object.assign(selectSchoolObject, { [index]: false });
    });

    let hiddenColumns: string[] = [];
    if (isAdmin && !activePlan) {
      hiddenColumns = [];
    } else if (isAdmin && !isDistrictActivePlan) {
      hiddenColumns = ["created_at", "school_name"];
    } else if (!isAdmin && !isDistrictActivePlan) {
      hiddenColumns = ["created_at", "school_name"];
    } else if (isAdmin && isDistrictActivePlan) {
      hiddenColumns = ["created_at"];
    } else if (!isAdmin && isDistrictActivePlan) {
      hiddenColumns = ["created_at"];
    }
    const hasTrueValue = Object.values(selectSchoolObject).some(val => val === true);
    return {
      selectedRowIds: hasTrueValue ? selectSchoolObject : {},
      hiddenColumns: hiddenColumns
    };
  }, [activePlan, filteredData, isAdmin, isDistrictActivePlan, selectedSurveyDeployment]);

  const showComparisonDialog = () => {
    setComparisonReportDialogOpen(true);
  };
  const showSurveyDeploymentSelection: any = useMemo(() => {
    return (
      
      isTeamPlanTable && (isDistrictActivePlan || !isDistrictActivePlan) && !isManagedDeletedDataWorkspace
    );
  }, [isTeamPlanTable, isDistrictActivePlan, isManagedDeletedDataWorkspace]);

  return (
    <>
      <EntityTable
        data={filteredData}
        columns={columns}
        checkboxSelection={showSurveyDeploymentSelection}
        onSelectedChange={
          showSurveyDeploymentSelection ? handleSelectedChange : undefined
        }
        searchString={searchString}
        isCheckBoxDisable={isCheckBoxDisable}
        setSearchString={setSearchString}
        loading={loading || deleteLoading || closeLoading}
        noDataComp={nonIdealState}
        darkHeader={darkHeader}
        initialState={initialState}
        id={id}
      >
        {!isTeamPlanTable && (
          <Popover
            position={"bottom-left"}
            className="flex-grow justify-start"
            content={popoverContent()}
            minimal
          >
            <Button
              large
              minimal
              title={intl.formatMessage({
                id: "app.titles.survey-deployment",
              })}
              icon={"properties"}
              text={intl.formatMessage({
                id: "app.titles.survey-deployment",
              })}
              intent="primary"
              className="font-bold px-4"
            />
          </Popover>
        )}
      </EntityTable>
      <SurveyDeploymentResponsesDialog
        isOpen={isDialogOpen}
        setOpen={setIsDialogOpen}
        isTeamPlanDialog={isTeamPlanTable}
        {...dialogProps}
      />
      <SampleLettersDialog
        isOpen={isDownloadDialogOpen}
        setOpen={setDownloadDialogOpen}
        surveyDeploymentId={dialogProps?.surveyDeploymentId}
      />
      {showSurveyDeploymentSelection && !showSdDialog && (
        <div className="text-right mt-2">
         {isDistrictActivePlan && <Popover
            className="mr-2"
            popoverClassName="w-64"
            disabled={isComparisonReportDialogOpen}
            interactionKind={PopoverInteractionKind.HOVER}
            content={
              <div className="p-4">
                  {intl.formatMessage({
                    id: "app.survey-deployment.comparison-report-popover",
                  })}
                </div>
            }
          >
            <Button
              intent="primary"
              disabled={false}
              text={intl.formatMessage({
                id: "app.survey-deployment.comparison-reports",
              })}
              onClick={() => showComparisonDialog()}
            />
          </Popover>}
          {selectedDistrict?.is_dummy_district && <Popover
            popoverClassName="w-56"
            interactionKind={PopoverInteractionKind.HOVER}
            content={
              <div className="p-3 text-center">
                {intl.formatMessage({
                  id: "app.survey-deployment.veiw-select-data-popover",
                })}
              </div>
            }
            disabled={selectedSurveyDeployment.length <= 0}
          >
            <Button
              disabled={selectedSurveyDeployment.length <= 0}
              className="light-blue"
              intent="primary"
              onClick={() => navigateToDistrictComparisonTab("")}
              text={intl.formatMessage({
                id: "app.survey-deployment.veiw-selected-data",
              })}
            />
          </Popover>}
          <ComparisonReportDialog
            isOpen={isComparisonReportDialogOpen}
            setOpen={setComparisonReportDialogOpen}
            distinctSurveyDeployments={distinctSurveyDeployments}
            distinctSchoolYear={distinctSchoolYear}
            handleSelectChange={handleChangeReportDialog}
          />
        </div>
      )}
    </>
  );
};

export default SurveysDeploymentTable;
